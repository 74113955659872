export const getURLImage = (tipo, nivel) => {
  if (tipo !== "Via Liberada") {
    if (nivel === "Alagamento")
      return window.location.origin + "/img/alertas/Acúmulo de água.jpeg";
    if (nivel === "Sinistro de Trânsito")
      return window.location.origin + "/img/alertas/Sinistro de Trânsito.jpeg";
    if (nivel === "Obras na via")
      return window.location.origin + "/img/alertas/Obra na via.jpeg";
    if (nivel === "Evento")
      return window.location.origin + "/img/alertas/Evento Cultural.jpg";
    if (nivel === "Manifestação")
      return window.location.origin + "/img/alertas/Passeata.jpeg";
    if (nivel === "Bloqueio Ciclovia")
      return window.location.origin + "/img/alertas/Bloqueio Ciclovia.jpeg";
    if (nivel === "Queda de árvore")
      return window.location.origin + "/img/alertas/Queda de árvore.jpeg";
    if (nivel === "Desvio do Transporte Público")
      return (
        window.location.origin + "/img/alertas/Desvio Transporte Publico.jpeg"
      );
    if (nivel === "Desvio de Transito")
      return window.location.origin + "/img/alertas/Desvio trânsito.jpeg";
    if (nivel === "Lentidão na Via")
      return window.location.origin + "/img/alertas/Lentidao na via.jpg";
    if (nivel === "Obstrução na via")
      return window.location.origin + "/img/alertas/Obstrução na via.jpeg";
  }
  return window.location.origin + "/img/alertas/Via Liberada.jpeg";
};
